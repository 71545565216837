import React from "react"

import ProjectAnimations from "../../components/projects/projectAnimations"
import Slide from "../../components/projects/Slide"
import Spacer from "../../components/projects/Spacer"

import DataLocConSVG from "../../assets/svg/orbica/data-location-con.svg"

import IpadVideo from "../../assets/images/orbica/Friday-Orbica-Digital.mp4"
import LogoVideo from "../../assets/images/orbica/Orbica-animation.mp4"
import OrbicaNzVideo from "../../assets/images/orbica/Orbica NZ Animation.mp4"

import "./template-styles.scss"
import "./orbica.scss"
import Img from "../../assets/images/orbica-images"

// import IpadVideo from "../../assets/images/orbica/Orbica NZ Animation.mp4"

import SEO from "../../components/seo"
import RightArrowSVG from "../../assets/svg/right-arrow.svg"
import TransitionLink from "../../components/TransitionLink"
import NavigationOrbs from "../../components/NavigationOrbs"

// const black = "#212931"
const white = "#ffffff"
const darkGrey = "#2B2B2B"
const lightGrey = "#D5D5D5"
// const red = "#DE3E30"

const NewTemplate = () => {
  ProjectAnimations()
  return (
    <div className="project orbica">
      <SEO
        title="Orbica"
        description="Strategy and positioning for a bold challenger brand in the digital geography industry. Brand strategy, name, logomark, design platform, bespoke website, digital outputs, tone of voice, key messaging and copy content, brand and marketing collateral."
      />

      <NavigationOrbs />

      <Slide className="intro">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="orb1" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: darkGrey, color: white }}
        >
          <div>
            <h1>Orbica</h1>
            <p>
              GIS tech specialists Orbica resolve problems with location
              data-inspired solutions. They also dig deep into an organisation's
              untapped goldmine of raw location data to identify connections and
              exciting new opportunities to deliver value. They’re innovators
              and disruptors, and they’re turning the industry on its head. This
              is how we positioned them. <br />
              <br />
              Brand strategy, name, logomark, design platform, bespoke website,
              digital outputs, tone of voice, key messaging and copy content,
              brand and marketing collateral.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-1">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <video
            src={LogoVideo}
            autoPlay
            playsInline
            muted
            loop
            className="h-full object-cover"
          ></video>
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: lightGrey, color: darkGrey }}
        >
          <div>
            <p>
              A geo-related name and a dynamic logomark based on an augmented
              digital marker point. The logo is adaptable. It flips and changes
              to suit whatever its application.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-2">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text panel--image panel--image--svg panel--image--svg--large"
          style={{ backgroundColor: darkGrey }}
        >
          <DataLocConSVG />
        </div>
      </Slide>
      <Slide className="section-3">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <video
            src={OrbicaNzVideo}
            autoPlay
            playsInline
            muted
            loop
            className="h-full object-cover"
          ></video>
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: lightGrey, color: darkGrey }}
        >
          <div>
            <p>
              Orbica’s tagline is Data Location Connectivity, their core
              proposition. This informed the point-and-line design concept that
              runs throughout the platform, which has been applied to everything
              from digital outputs to the humble business card. The concept
              represents a versatile drawing tool.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-4">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="orb3" />
        </div>
      </Slide>

      <Slide className="section-5z">
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: darkGrey, color: white }}
        >
          <div>
            <p>
              Each member of the Orbica team has their own individualised
              business card. A low poly design tells their personal story, while
              red edging represents their connection to the team.
            </p>
          </div>
        </div>

        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="orb4" />
        </div>
      </Slide>

      <div className="section-5 carousel-container section-wrapper">
        <Spacer />

        <div className="items-container-parent pin-for-400 pin-fully-scrolled flex flex-wrap">
          <Spacer />
          <div
            className="items-container panel--w-half flex flex-col "
            // style={{
            //   backgroundImage: "url(/images/orb-4.jpg)",
            //   backgroundSize: "200%",
            //   backgroundRepeat: "repeat",
            // }}
          >
            {/* <CarouselSVG1 className="item" />
            <CarouselSVG2 className="item" />
            <CarouselSVG3 className="item" />
            <CarouselSVG4 className="item" /> */}
            <Img src="orb4a" className="item" />
            <Img src="orb4b" className="item" />
            <Img src="orb4c" className="item" />
            <Img src="orb4d" className="item" />
          </div>
        </div>
      </div>

      <Slide className="section-6">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="orb1" />
        </div>
      </Slide>
      <Slide className="section-7">
        <div
          className="panel--w-full panel--h-screen"
          style={{ backgroundColor: darkGrey }}
        >
          <div className="phones-container">
            <div className="phone">
              <Img src="orb6a" />
            </div>
            <div className="phone">
              <Img src="orb6b" />{" "}
            </div>
            <div className="phone">
              <Img src="orb6c" />
            </div>
          </div>
        </div>
      </Slide>

      <Slide className="section-8">
        <div
          className="ipad panel--w-full panel--h-screen screen-container"
          style={{ backgroundColor: darkGrey }}
        >
          <div className="screen-container__inner-container">
            <Img src="orb5" className="screen-container__frame" />
            <div className="screen-container__screen">
              <video src={IpadVideo} autoPlay playsInline muted loop></video>
            </div>
          </div>
        </div>
      </Slide>
      <Slide className="section-9">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: lightGrey, color: darkGrey }}
        >
          <div>
            <p>
              Their engaging, bespoke website uses animations and robust
              information hierarchies to showcase their services and connect
              with their domestic and international target markets.
              <br />
              <br />
              Experience the full site{" "}
              <a
                href="https://orbica.co.nz/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold underline"
              >
                here
              </a>
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-10">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="orb7" />
        </div>
      </Slide>

      <div className="section-11 flex flex-wrap pin-for-400">
        <div
          className="panel--w-half panel--h-screen panel--image panel--image--centered"
          style={{ backgroundColor: lightGrey }}
        >
          <Img src="orb8" />
        </div>
        <Spacer />
      </div>
      <Spacer />

      <Slide className="section-12">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="orb10" />
        </div>
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="orb9" />
        </div>
      </Slide>
      <div className="section-13 flex flex-wrap pin-for-400">
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: lightGrey, color: darkGrey }}
        >
          <div>
            <p>
              T-shirts connect the team to the company and each other. An
              interactive playboard inspires new ideas and creativity. External
              illuminated signage and internal wayfinding signage help you
              navigate to Orbica’s Christchurch offices.
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-14">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="orb11" />
        </div>
      </Slide>
      <Slide className="section-15">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="orb12" />
        </div>
      </Slide>
      <Slide className="section-16">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: lightGrey, color: darkGrey }}
        >
          <div>
            <p>
              Recognised with multiple awards for SME business enterprise and
              tech-innovation, Orbica went from tech-start up to industry leader
              with global operational locations in less than three years. <br />
              <br />
              <br />
              <TransitionLink
                to="/projects/cha-cha/"
                color="#db5566"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Next project
              </TransitionLink>
              <br />
              <TransitionLink
                to="/#projects"
                color="white"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block transform rotate-180"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Back to all projects
              </TransitionLink>
            </p>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default NewTemplate
